<template>
  <div class="connect-container align-content-stretch d-flex flex-wrap">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-5">
          <div class="auth-form">
            <div class="row">
              <div class="col">
                <div class="logo-box">
                  <a href="#" class="logo-text">Soft Serve Support</a>
                </div>
                <div v-if="alert.status" class="alert text-center alert-warning">
                  {{ alert.message }}
                </div>
                <eralp-form @submit="HANDLE_SUBMIT">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      v-model="form.email"
                      maxlength="50"
                      required
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password"
                      required
                      minlength="6"
                      maxlength="20"
                      id="password"
                      placeholder="Password"
                    />
                  </div>
                </eralp-form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-none d-lg-block d-xl-block">
          <div class="auth-image"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alert: {
        status: false,
        message: "",
      },
      form: {
        email: "saban@softservesupport.com.au",
        password: "123456",
      },
    };
  },
  methods: {
    async HANDLE_SUBMIT(e, loading) {
      this.alert.status = false;
      e.preventDefault();
      loading();
      const response = await this.$api.login(this.form);
      if (response.result === "OK" && response.message === "OK") {
        this.$store.commit("SET_ACTIVE_USER", {
          ...response.data,
          form: this.form,
        });
        this.$router.push("/");
      } else if (response.message === "LOGINERROR") {
        this.alert = {
          status: true,
          message: "Your email or password wrong.",
        };
      } else {
        this.alert = {
          status: true,
          message: "Something went wrong. Please Try Again.",
        };
      }
      loading(false);
    },
  },
};
</script>

<style>
</style>