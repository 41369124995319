<template>
  <SignIn />
</template>

<script>
import SignIn from "../components/signIn/index.vue";
export default {
  components: {
    SignIn,
  },
  beforeDestroy() {
    this.$eralp.ToggleButton("sign-in");
    this.$eralp.ToggleButton("auth-page");
  },
  mounted() {
    this.$eralp.ToggleButton("sign-in");
    this.$eralp.ToggleButton("auth-page");
  },
};
</script>

<style>
</style>